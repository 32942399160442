<template>
  <div class="login d-flex justify-content-center align-items-center">
    <div class="p-5">
      <mdb-card>
        <mdb-card-body>
          <form v-if="!loginLoading">
            <p class="h4 text-center mb-4">Lamprecht Stundentool Anmeldung</p>
            <div class="grey-text">
              <mdb-input
                class="mx-auto"
                v-model="name"
                label="Dein Name"
                icon="user"
                type="text"
                required
              />
              <mdb-input
                class="mx-auto"
                v-model="password"
                label="Dein Passwort"
                icon="lock"
                type="password"
                required
              />
            </div>
            <div class="text-center">
              <p v-if="error" class="login__error-text">{{errorMessage}}</p>
              <mdb-btn class="login__button" @click="login" color="primary">Anmelden</mdb-btn>
            </div>
          </form>
          <div class="d-flex flex-column justify-content-center" v-if="loginLoading">
            <div class="h5">Anmeldung...</div>
            <div class="spinner-border mx-auto" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</template>

<script>
import { mdbInput, mdbBtn, mdbCard, mdbCardBody } from 'mdbvue';
import { mapGetters } from 'vuex';

export default {
  components: {
    mdbInput,
    mdbBtn,
    mdbCard,
    mdbCardBody,
  },
  data() {
    return {
      name: '',
      password: '',
      loginLoading: false,
      error: false,
      errorMessage: '',
    };
  },
  mounted() {
    if (localStorage.getItem('JWT') !== null && localStorage.getItem('JWT') !== '') {
      this.$store.dispatch('REFRESH_TOKEN', localStorage.getItem('JWT'));
    }
  },
  computed: {
    ...mapGetters(['refreshToken']),
  },
  methods: {
    login() {
      if(this.name && this.password) {
        this.error = false;
        this.errorMessage = '';

        this.loginLoading = true;

        this.$store
          .dispatch('AUTH_REQUEST', {
            name: this.name,
            password: this.password,
          })
          .then(() => {
            this.loginLoading = false;
            this.$router.replace({ name: 'Stundentool' });
          })
          .catch(() => {
            this.error = true;
            this.loginLoading = false;
            this.errorMessage = 'Name oder Passwort falsch oder Netwerkprobleme!'
          });
      } else {
        this.error = true;
        this.errorMessage = 'Name und Passwort muss eingegeben werden!';
      }
    },
  },
};
</script>

<style></style>
